import { Component, inject, input, model } from '@angular/core';
import { LocationFormComponent } from '../location-form/location-form.component';
import { FormDialogComponent } from '@desquare/components/common/src/modals/form-dialog.component';
import { ILocationForm } from '@desquare/interfaces';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  standalone: true,
  imports: [FormDialogComponent, LocationFormComponent],
  selector: 'app-create-location-dialog',
  template: `<app-form-dialog
    [headerText]="create() ? 'CREATE_LOCATION' : 'EDIT_LOCATION'"
    [showFooter]="false"
  >
    <app-location-form
      [isCreate]="create()"
      [location]="location()"
      [showDeleteButton]="false"
      (closeParentModal)="closeModal()"
    />
  </app-form-dialog> `,
  styles: [``],
})
export class CreateLocationDialogComponent {
  modal = inject(NgbActiveModal);
  create = model(true);
  location = model<ILocationForm>({});

  closeModal() {
    console.log('closeModal');

    this.modal.close();
  }
}
