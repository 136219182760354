/**
 * Validates whether the specified key's value in the input differs from the form value
 * and checks for duplicates in the store based on the key, ignoring case.
 *
 * @template T - The type of the objects being validated.
 * @template S - The type of the store array.
 * @param key - The property name to check for duplicate values.
 * @param input - The input object containing the key-value to validate.
 * @param formValue - The form's current value object to compare against.
 * @param store - An array of objects to search for duplicate values based on the key.
 * @returns `true` if a duplicate is found; otherwise, `false`.
 */
export function validateCheckDuplicates<
  T extends Record<string, any>,
  S extends Record<string, any>,
>(key: string, input: T, formValue: T, store: S[]): boolean {
  if (!input || !formValue) return false;
  return input[key] !== formValue[key]
    ? store.some(
        (element) =>
          element[key]?.toLowerCase() === formValue[key]?.toLowerCase(),
      )
    : false;
}

export function validateRequired(
  formValue: undefined | string | number,
): boolean {
  return formValue === undefined || formValue === '';
}
