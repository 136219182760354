import { Directive, Output, inject } from '@angular/core';
import { NgForm } from '@angular/forms';
import { debounceTime, map } from 'rxjs';

@Directive({
  standalone: true,
  selector: 'form',
})
export class FormDirective {
  private readonly ngForm = inject(NgForm, { self: true });
  @Output() public readonly formValueChange =
    this.ngForm.form.valueChanges.pipe(debounceTime(0));
  @Output() public readonly dirtyChange = this.ngForm.form.statusChanges.pipe(
    map(() => this.ngForm.form.dirty),
  );
  @Output() public readonly validChange = this.ngForm.form.statusChanges.pipe(
    map(() => this.ngForm.form.valid),
  );
}
