@if (showMapOnly) {
  <div id="map-container" class="map-container">
    <div id="map1" class="map"></div>
    <div id="map2" class="map"></div>
  </div>
} @else {
  <form
    #form="ngForm"
    (formValueChange)="formValue.set($event)"
    (validChange)="formValid.set($event)"
    (dirtyChange)="formDirty.set($event)"
  >
    @if (showLocationName) {
      <div class="row mt-2">
        <div class="col-12 form-group text-nowrap mb-3">
          <label class="form-label">{{ 'LOCATION_NAME' | translate }}*</label>
          @if (vm().nameIsRequired) {
            <span class="error">
              {{ 'REQUIRED' | translate }}
            </span>
          }
          @if (vm().nameAlreadyExists) {
            <span class="error">
              {{ 'DUPLICATE_LOCATION_NAME' | translate }}
            </span>
          }
          <input
            type="text"
            name="name"
            class="form-control form-control-dark"
            [ngModel]="vm().formValue.name"
            autocomplete="do-not-autofill"
            required
          />
        </div>
      </div>
    }
    @if (showLocationSearch) {
      <div class="row">
        <div class="col-6 form-group text-nowrap mb-3">
          <label class="form-label">{{ 'FIND_LOCATION' | translate }}</label>
          <div id="geocoder-container">
            @if (isCreate) {
              <div id="geocoder1" class="geocoder"></div>
            }
            <div id="geocoder2" class="geocoder"></div>
          </div>
        </div>
        <div class="col-3 d-flex form-group text-nowrap mb-3">
          <button
            class="mt-auto btn btn-block"
            (click)="setAddressToMapResponse()"
            [class.btn-outline-primary]="!editingLocation()"
            [class.btn-primary]="editingLocation()"
            [disabled]="!editingLocation()"
          >
            {{ 'UPDATE_LOCATION_BUTTON' | translate }}
          </button>
        </div>
        <div class="col-3 d-flex form-group text-nowrap mb-3">
          @if (!editingLocation()) {
            <button
              class="mt-auto btn btn-block btn-primary"
              (click)="editOnTheMap()"
              [disabled]="editingLocation()"
            >
              {{ 'EDIT_LOCATION_BUTTON' | translate }}
            </button>
          } @else {
            <button
              class="mt-auto btn btn-block btn-outline-danger"
              (click)="cancelEditing()"
            >
              {{ 'CANCEL' | translate }}
            </button>
          }
        </div>
      </div>
    }
    <div class="row">
      <div class="d-none d-sm-block col-7">
        <div id="map-container" class="map-container">
          <div #mapboxContainer id="map1" class="map"></div>
          <!-- <div id="map2" class="map"></div> -->
        </div>
      </div>
      <div class="col-12 col-sm-5">
        <div class="form-group text-nowrap mb-3">
          <label class="form-label"
            >{{ 'STREET_ADDRESS' | translate }} 1*</label
          >
          @if (vm().streetAddress1IsRequired) {
            <span class="error">
              {{ 'REQUIRED' | translate }}
            </span>
          }
          <input
            type="text"
            name="streetAddress1"
            class="form-control form-control-dark"
            [ngModel]="vm().formValue?.streetAddress1 ?? ''"
            autocomplete="do-not-autofill"
          />
        </div>
        <div class="form-group text-nowrap mb-3">
          <label class="form-label">{{ 'STREET_ADDRESS' | translate }} 2</label>
          <input
            type="text"
            name="streetAddress2"
            class="form-control form-control-dark"
            [ngModel]="vm().formValue?.streetAddress2 ?? undefined"
            autocomplete="do-not-autofill"
          />
        </div>
        <div class="row">
          <div class="col-4 form-group text-nowrap mb-3">
            <label class="form-label">{{ 'ZIP' | translate }}*</label>
            @if (vm().zipIsRequired) {
              <span class="error">
                {{ 'REQUIRED' | translate }}
              </span>
            }
            <input
              type="text"
              name="zip"
              class="form-control form-control-dark"
              [ngModel]="vm().formValue?.zip"
              autocomplete="do-not-autofill"
            />
          </div>
          <div class="col-8 form-group text-nowrap mb-3">
            <label class="form-label">{{ 'CITY' | translate }}*</label>
            @if (vm().cityIsRequired) {
              <span class="error">
                {{ 'REQUIRED' | translate }}
              </span>
            }
            <input
              type="text"
              name="city"
              class="form-control form-control-dark"
              [ngModel]="vm().formValue?.city"
              autocomplete="do-not-autofill"
            />
          </div>
        </div>
        <div class="form-group text-nowrap mb-3">
          <label class="form-label">{{ 'COUNTRY' | translate }}*</label>
          @if (vm().countryIsRequired) {
            <span class="error">
              {{ 'REQUIRED' | translate }}
            </span>
          }
          <select
            [ngModel]="vm().formValue?.country"
            name="country"
            class="form-control form-control-dark form-select form-select-dark"
          >
            <option value="null" disabled>{{ 'COUNTRY' | translate }}</option>
            @for (country of countries; track country.name) {
              <option [ngValue]="country.name">
                {{ country.name | translate }}
              </option>
            }
          </select>
        </div>
        <div class="form-group text-nowrap mb-3">
          <label class="form-label">{{ 'PHONE_NUMBER' | translate }}</label>
          <!-- @if (!formValid()) {
            <span class="error">
              {{ 'PHONE_NUMBER_LENGTH_ERROR' | translate }}
            </span>
          } -->
          <input
            type="text"
            name="phoneNumber"
            mask="0000000099"
            class="form-control form-control-dark"
            [ngModel]="vm().formValue?.phoneNumber"
            autocomplete="do-not-autofill"
          />
        </div>
        <div class="form-group text-nowrap mb-3" hidden>
          <label class="form-label">{{ 'REGION' | translate }}</label>
          <input
            type="text"
            name="region"
            class="form-control form-control-dark"
            [ngModel]="vm().formValue?.region"
            autocomplete="do-not-autofill"
          />
        </div>
        <div class="row" ngModelGroup="coordinates" hidden>
          <div class="col form-group text-nowrap mb-3">
            <label class="form-label">{{ 'LATITUDE' | translate }}</label>
            <input
              type="number"
              name="x"
              class="form-control form-control-dark"
              [ngModel]="vm().formValue?.coordinates?.x"
              autocomplete="do-not-autofill"
            />
          </div>
          <div class="col form-group text-nowrap mb-3">
            <label class="form-label">{{ 'LONGITUDE' | translate }}</label>
            <input
              type="number"
              name="y"
              class="form-control form-control-dark"
              [ngModel]="vm().formValue?.coordinates?.y"
              autocomplete="do-not-autofill"
            />
          </div>
        </div>
      </div>
    </div>
    @if (showFooter()) {
      <div class="row controls">
        <div
          class="col-12 mt-2 d-flex justify-content-end"
          [class.justify-content-between]="!isCreate"
        >
          @if (showDeleteButton()) {
            <button
              class="deactivate-btn btn btn-outline-danger waves-effect waves-light mt-2"
              class="btn btn-outline-danger mt-2 mx-2"
              (click)="openDeleteLocationDialog()"
            >
              <i class="mdi mdi-delete"></i>
              {{ 'DELETE' | translate }}
            </button>
          }

          <button
            [disabled]="
              formIsNotValid() ||
              !vm().formDirty ||
              locationsStore.operationLoading()
            "
            class="btn btn-outline-success mt-2"
            (click)="saveLocation()"
          >
            @if (locationsStore.operationLoading()) {
              <div
                class="spinner-border spinner-border-sm text-light"
                role="status"
                aria-hidden="true"
              >
                <span class="visually-hidden"
                  >{{ 'SAVING' | translate }}...</span
                >
              </div>
            } @else {
              <i class="mdi mdi-content-save"></i>
              {{ 'SAVE' | translate }}
            }
          </button>
        </div>
      </div>
    }
  </form>
  <!-- <pre>
    form:{{ formValue() | json }} 
  </pre>
  <pre>
    isDefaultLocation:
    {{ isDefaultLocation() | json }}
  </pre> -->
}
