import { Injectable } from '@angular/core';
import { environment } from '@desquare/environments';
import mapboxgl from 'mapbox-gl';

@Injectable({
  providedIn: 'root',
})
export class MapboxService {
  constructor() {
    mapboxgl.accessToken = environment.mapbox.accessToken;
  }

  readonly DefaultMapCoords: [number, number] = [18.07718, 59.334583];
  readonly LightStyle =
    'mapbox://styles/peterbrauner/cki8qdo4obra619quyb3iver0';
  readonly DarkStyle = 'mapbox://styles/peterbrauner/cki8q9pvb0xp419o10kqmhi2k';

  lngLatToCoords(coords: mapboxgl.LngLat): [number, number] {
    return [coords.lng, coords.lat];
  }
  lngLatToPoint(coords: mapboxgl.LngLat): mapboxgl.Point {
    return new mapboxgl.Point(coords.lng, coords.lat);
  }
  coordsToPoint([lng, lat]: [number, number]) {
    return new mapboxgl.Point(lng, lat);
  }
}
