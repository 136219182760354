import {
  signalStore,
  withState,
  withComputed,
  withMethods,
  patchState,
  withHooks,
} from '@ngrx/signals';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { tapResponse } from '@ngrx/operators';
import { CurrentUserService, LocationService } from '@desquare/services';
import { Signal, computed, inject, signal } from '@angular/core';
import { distinctUntilChanged, pipe, switchMap } from 'rxjs';
import { GraphQLFormattedError } from 'graphql';
import { DeleteLocationInput, Location } from '@designage/gql';
import { ILocation, ILocationForm } from '@desquare/interfaces';

export interface ILocationsSignal {
  locations: ILocation[];
  loading: boolean;
  operationLoading: boolean;
  errors: GraphQLFormattedError<Record<string, any>>[] | null;
}

const initialState: ILocationsSignal = {
  locations: [],
  loading: true,
  operationLoading: false,
  errors: null,
};

export const LocationsStore = signalStore(
  { providedIn: 'root' },
  withState(initialState),

  withMethods((store, locationService = inject(LocationService)) => ({
    getLocationsFromApi: rxMethod<void>(
      pipe(
        distinctUntilChanged(),
        switchMap(() => {
          return locationService.getProfileLocationsFromApi$().pipe(
            tapResponse({
              next: ({ locations, errors, loading }) => {
                console.log('location loading', loading);

                patchState(store, {
                  locations,
                  loading,
                  errors: errors ? [...errors] : [],
                });
              },
              error: console.error,
              finalize: () => patchState(store, { loading: false }),
            }),
          );
        }),
      ),
    ),
    getLocation: (locationId: string): Location | undefined => {
      return store.locations().find((x) => x.id === locationId) ?? undefined;
    },
    updateLocation: async (location: ILocationForm) => {
      patchState(store, { operationLoading: true });
      await locationService.updateLocation(location);
      patchState(store, { operationLoading: false });
    },
    createLocation: async (
      location: ILocationForm,
    ): Promise<Location | null> => {
      patchState(store, { operationLoading: true });
      const createLocation = await locationService.createLocation(location);
      patchState(store, { operationLoading: false });
      return createLocation;
    },
    deleteLocation: async (locationId: string, newLocationId?: string) => {
      patchState(store, { operationLoading: true });
      const input: DeleteLocationInput = {
        id: locationId,
        newLocationId,
      };
      await locationService.deleteLocation(input);
      patchState(store, { operationLoading: false });
    },
    checkDuplicateLocation: (name: string): boolean => {
      const duplicateLocations = store
        .locations()
        .filter((x) => x.name === name);
      if (duplicateLocations.length > 0) {
        return true;
      }
      return false;
    },
  })),
  withHooks((store) => ({
    onInit() {
      // Load products when the store is initialized
      store.getLocationsFromApi();
    },
  })),
  withComputed((store, currentUserService = inject(CurrentUserService)) => ({
    defaultLocation: computed(() =>
      store
        .locations()
        .find(
          (location) =>
            location.id ===
            currentUserService.currentProfile?.defaultLocationId,
        ),
    ),
  })),
);
